export default {
  data: () => ({
    // Must be set by implementing component
    config: {}
  }),

  computed: {
    locale () {
      return this.$i18n.locale
    }
  }
}
