import { createAuth0Client } from '@auth0/auth0-spa-js'
import { WebAuth } from 'auth0-js'
import * as Sentry from '@sentry/vue'
import bus, { eventNames } from '@/lib/eventBus'

const DEFAULT_REDIRECT_CALLBACK = () => {
  return window.history.replaceState({}, document.title, window.location.pathname)
}

const isRedirectedAfterAuthentication = () => (
  window.location.search.includes('code=') &&
  window.location.search.includes('state=')
)

function getLocationBeforeLogin () {
  const location = sessionStorage.getItem('locationBeforeLogin')
  sessionStorage.removeItem('locationBeforeLogin')
  return location
}

function prepareUser (user) {
  if (user) {
    return {
      ...user,
      ...user[process.env.VUE_APP_AUTH0_META_CLAIM] || {}
    }
  }
  return user
}

let instance

export const getInstance = () => instance

export const createAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  domain,
  clientId,
  audience,
  databaseConnection
}) => {
  if (instance) return instance

  instance = {
    loading: true,
    isAuthenticated: false,
    user: {},
    auth0Client: null,
    webAuth: null,
    error: null,
    async onCreated () {
      this.auth0Client = await createAuth0Client({
        domain,
        authorizationParams: {
          audience,
          redirect_uri: redirectUri
        },
        clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true
      })

      this.webAuth = new WebAuth({
        domain,
        audience,
        clientID: clientId,
        redirectUri,
        responseType: 'code'
      })

      try {
        if (isRedirectedAfterAuthentication()) {
          await this.auth0Client.getTokenSilently()
          onRedirectCallback(getLocationBeforeLogin())
        }
      } catch (err) {
        this.error = err
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = prepareUser(await this.auth0Client.getUser())
        this.loading = false

        if (this.isAuthenticated) {
          bus.$emit(eventNames.USER_LOGGED_IN, this.user.companyId)
        }

        Sentry.configureScope(scope => {
          if (this.user) {
            scope.setTag('companyId', this.user.companyId)
            scope.setUser({ id: this.user.sub.split('|')?.[1] }) // sub: 'auth0|some-id'
          } else {
            scope.clear()
          }
        })
      }
    },
    login (username, password) {
      return new Promise((resolve, reject) => {
        this.webAuth.login({
          username,
          password,
          realm: databaseConnection
        }, err => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })
      })
    },
    logout (options) {
      localStorage.clear()
      return this.auth0Client.logout(options)
    },
    changePassword ({ email }) {
      return new Promise((resolve, reject) => {
        this.webAuth.changePassword({
          connection: databaseConnection,
          email
        }, function (err, res) {
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        })
      })
    },
    getIdTokenClaims (options) {
      return this.auth0Client.getIdTokenClaims(options)
    },
    async getTokenSilently (options) {
      const token = await this.auth0Client.getTokenSilently(options)
      this.isAuthenticated = await this.auth0Client.isAuthenticated()
      this.user = prepareUser(await this.auth0Client.getUser())
      return token
    }
  }
  return instance
}
