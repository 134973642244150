import { getInstance } from 'feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import IDENTITY from '@/queries/Identity.gql'
import featureNames from '@/lib/featureNames'

const getPermissions = async (apollo) => {
  const { data } = await apollo.defaultClient.query({
    query: IDENTITY,
    fetchPolicy: 'cache-first'
  })
  return data?.identity?.user?.role?.permissions
}

export function permission (apolloProvider, permission) {
  return async (to, from, next) => {
    const authService = getAuthInstance()
    const companyId = authService?.user?.companyId
    if (['company-creation', 'logout'].includes(to.name)) return next()

    const featureConfig = getInstance()
    await featureConfig.loadAllFeaturesForCompany(companyId, { apolloProvider, reload: false })
    const isAuthorizationActive = featureConfig.feature(featureNames.AUTHORIZATION)?.isActive
    if (!isAuthorizationActive) {
      return next()
    }
    const permissions = await getPermissions(apolloProvider)
    if (permissions?.includes(permission)) {
      return next()
    }
    return next({ name: 'dashboard', replace: true })
  }
}
