import * as Sentry from '@sentry/vue'
import bus, { eventNames } from '@/lib/eventBus'
import decodeJwt from 'jwt-decode'
import { onoffice } from '@/lib/features'

function inIframe () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function isOnofficeProvider () {
  if (!onoffice?.isEnabled || !inIframe()) {
    return false
  }
  const token = sessionStorage.getItem('onOffice')
  if (token) {
    const jwt = decodeJwt(token)
    if (jwt.exp * 1000 > Date.now()) {
      return true
    }
  }
  if (!window.location.href.includes('onoffice/service')) {
    return false
  }
  const { apiClaim: extendedClaim, timestamp, customerName, customerWebId, userId, signature } = Object.fromEntries(new URLSearchParams(window.location.search).entries())
  return [extendedClaim, timestamp, customerName, customerWebId, userId, signature].every(Boolean)
}

export const onofficeAuth = {
  loading: true,
  isAuthenticated: false,
  token: null,
  user: {},
  error: null,

  async onCreated () {
    await this.login()

    setTimeout(() => {
      window.alert('Ihre Sitzung ist abgelaufen. Bitte laden Sie das Fenster neu.')
      Sentry.captureMessage('onOffice Session expired')
    }, 11 * 60 * 60 * 1000)

    if (this.isAuthenticated) {
      bus.$emit(eventNames.USER_LOGGED_IN, this.user.companyId)
    }

    Sentry.configureScope(scope => {
      if (this.user) {
        scope.setTag('companyId', this.user.companyId)
        scope.setUser({ id: this.user.userId })
      } else {
        scope.clear()
      }
    })
  },
  async login () {
    this.loading = true
    const token = sessionStorage.getItem('onOffice')
    if (token) {
      try {
        const jwt = decodeJwt(token)
        if (jwt.exp * 1000 > Date.now()) {
          this.token = token
          this.user = jwt.user
          this.isAuthenticated = true
          this.loading = false
          return
        }
      } catch (error) { }
    }

    const marketplaceApp = (window.location.href.match(/service\/(.*)\?/) || [])[1]?.toUpperCase()

    try {
      const axios = (await import('axios')).default
      const { data: { token, user } } = await axios.post(process.env.VUE_APP_BOTTIMMO_SERVICE + '/onoffice/login', {
        url: window.location.href,
        marketplaceApp
      })
      sessionStorage.setItem('onOffice', token)
      this.token = token
      this.user = user
    } catch (error) {
      if (error?.response.status === 404) {
        this.error = 'userNotFound'
      } else if (error?.response.status === 403) {
        this.error = 'marketplaceAppNotAllowed'
      } else {
        this.error = 'unknown'
      }
    } finally {
      this.isAuthenticated = Boolean(this.token)
      this.loading = false
    }
  },
  logout () {
    sessionStorage.removeItem('onOffice')
    // Close onOffice parent window
    // eslint-disable-next-line no-undef
    processTypeToonOffice && processTypeToonOffice('onOffice.service.closeProviderWindow')
  },
  getTokenSilently () {
    return this.token
  },
  changePassword () {
    throw new Error('changePassword not Possible for onOffice user')
  },
  getIdTokenClaims () {
    throw new Error('getIdTokenClaims not Possible for onOffice user')
  }
}
