<template>
  <v-app v-if="dataReady">
    <!-- The banner should be loaded first -->
    <IubendaConsentBanner />
    <cookie-consent />

    <v-progress-linear
      v-if="showSpinner"
      indeterminate
      color="primary"
    />
    <template v-else>
      <navigation
        v-if="showNavigation"
        class="hidden-print-only"
        :is-drawer-collapsible="isDrawerCollapsible"
      />
      <v-main
        :data-btm-cid="publicCompanyId"
        :class="{'main-collapsed-nav': isDrawerCollapsible && $vuetify.breakpoint.lgAndUp, 'grey lighten-4': showBackground}"
      >
        <slot />
      </v-main>
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.FEEDBACK_TICKET"
      >
        <feedback :config="feature.config" />
      </Feature>
    </template>
  </v-app>
</template>

<script>
import Navigation from '@/modules/navigation'
import IubendaConsentBanner from '@/modules/iubenda/ConsentBanner'
import CookieConsent from '@/modules/cookieConsent'
import Feedback from '@/modules/feedback'
import featureMixin from '@/mixins/feature'
import COMPANY_STATE from '@/guards/CompanyState.gql'

export default {
  components: {
    Navigation,
    IubendaConsentBanner,
    CookieConsent,
    Feedback
  },
  mixins: [featureMixin],
  computed: {
    dataReady () {
      const isSignUpFeatureActive = this.isFeatureActive(this.featureNames.SIGNUP)
      return !isSignUpFeatureActive || (isSignUpFeatureActive && this.company)
    },
    showSpinner () {
      return this.$auth.isAuthenticated && !this.$features.loaded && this.$auth.user.companyId
    },
    showNavigation () {
      if (this.$auth?.user?.companyId) {
        const routesWithoutNavbar = [
          '/products',
          '/signup-markero/company-info-url',
          '/signup-markero/company-info-company',
          '/signup-markero/company-info-origin',
          '/signup-markero/company-info-industry',
          '/signup-markero/company-info-description',
          '/signup-markero/company-info-url-scrape',
          '/signup-markero/company-info-success',
          '/auth/logout',
          '/payment',
          '/payment-finalize/signup',
          '/product-packages',
          '/register'
        ]

        const isSignUpFeatureActive = this.isFeatureActive(this.featureNames.SIGNUP)
        if (isSignUpFeatureActive) routesWithoutNavbar.push(...['/registration', '/'])
        const isCrmFeatureActive = this.isFeatureActive(this.featureNames.CRM)
        if (isCrmFeatureActive) routesWithoutNavbar.push('/onoffice/activate')

        return !routesWithoutNavbar.includes(this.$route.path)
      }
      return false
    },
    isDrawerCollapsible () {
      return this.$vuetify.breakpoint.mdAndUp && ['/leads/list', '/leads/pipeline', '/content-creator'].includes(this.$route.path)
    },
    publicCompanyId () {
      return this.$auth?.user?.companyId
    },
    showBackground () {
      return this.$route.path !== '/products'
    }
  },
  apollo: {
    company: {
      query: COMPANY_STATE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId || !this.isFeatureActive(this.featureNames.SIGNUP)
      }
    }
  }
}
</script>
<style scoped>
@media print{
.v-main {
    padding: 0 !important;
  }
}
.main-collapsed-nav{
  padding-left: 60px !important;
}
</style>
