<template>
  <AuthView>
    <div class="flex-1">
      <h1 class="display-1 font-weight-black text-center">
        Ihr Zugang wird erstellt!
      </h1>

      <h2 class="my-12 text-center">
        Bitte warten Sie einen Moment.
      </h2>
      <div class="d-flex w-full justify-space-around">
        <v-progress-circular
          :rotate="360"
          :size="200"
          :width="25"
          :value="progress"
          color="primary"
        >
          <h2> {{ progress }}%</h2>
        </v-progress-circular>
      </div>
    </div>
  </AuthView>
</template>

<script>
import AuthView from './AuthView.vue'

export default {
  components: {
    AuthView
  },
  data () {
    return {
      progress: 0
    }
  },
  created () {
    this.updateProgress(10)
  },
  methods: {
    async updateProgress (value) {
      await this.$auth.getTokenSilently({
        cacheMode: 'off'
      })

      if (this.$auth.user.companyId) {
        return this.$router.push({ name: 'registration' })
      }

      this.progress = value
      if (this.progress < 100) {
        setTimeout(() => {
          this.updateProgress(this.progress + 10)
        }, this.progress * 100)
      }
    }
  }
}
</script>
