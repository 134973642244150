<template>
  <component :is="component" />
</template>
<script>
import brandingMixin from '@/mixins/branding'
import LoginMarkero from './markero/index'
import LoginBottimmo from './bottimmo/index'
export default {
  mixins: [brandingMixin],
  computed: {
    component () {
      return this.isPlatformMarkero ? LoginMarkero : LoginBottimmo
    }
  }
}
</script>
