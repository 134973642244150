import Vue from 'vue'
import { isOnofficeProvider } from './onoffice'

let instance

export const getInstance = () => instance

export const useAuth = ({
  auth0,
  onofficeAuth
}) => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        provider: null
      }
    },
    computed: {
      user () {
        return this.provider.user
      },
      loading () {
        return this.provider.loading
      },
      isAuthenticated () {
        return this.provider.isAuthenticated
      },
      auth0Client () {
        return this.provider.auth0Client
      },
      webAuth () {
        return this.provider.webAuth
      },
      error () {
        return this.provider.error
      }
    },
    async created () {
      if (isOnofficeProvider()) {
        this.provider = onofficeAuth
      } else {
        this.provider = auth0
      }
      await this.provider.onCreated()
    },
    methods: {
      login (...params) {
        return this.provider.login(...params)
      },
      logout (...params) {
        return this.provider.logout(...params)
      },
      changePassword (...params) {
        return this.provider.changePassword(...params)
      },
      getIdTokenClaims (...params) {
        return this.provider.getIdTokenClaims(...params)
      },
      async getTokenSilently (...params) {
        return this.provider.getTokenSilently(...params)
      },
      rememberLocationBeforeLogin () {
        sessionStorage.setItem('locationBeforeLogin', window.location.pathname)
      }
    }
  })

  return instance
}

export const AuthPlugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth(options)
  }
}
