import bus, { eventNames } from '@/lib/eventBus'

const MAX_ANIMATION_COUNT_PER_WEEK = 3

export const AnimationEvents = Object.freeze({
  CONTENT_DOWNLOADED: 'CONTENT_DOWNLOADED',
  CONTENT_POSTED: 'CONTENT_POSTED'
})

const onlyOnce = (count) => count === 0
const everyXTimes = (x) => (count) => count % x === 0

const showAnimationForEvent = {
  [AnimationEvents.CONTENT_DOWNLOADED]: onlyOnce,
  [AnimationEvents.CONTENT_POSTED]: everyXTimes(10)
}

function getWeekNumber (date) {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

bus.$on(eventNames.TRIGGER_ANIMATION, (event) => {
  const currentDate = new Date()
  const currentWeek = getWeekNumber(currentDate)

  const storedAnimationDate = localStorage.getItem('animation.date')
  const latestAnimationDate = new Date(storedAnimationDate)
  const latestAnimationWeek = getWeekNumber(latestAnimationDate)

  // If new week, reset count
  if (!storedAnimationDate || currentWeek !== latestAnimationWeek) {
    localStorage.setItem('animation.date', currentDate)
    localStorage.setItem('animation.count', 0)
  }

  const animationCount = parseInt(localStorage.getItem('animation.count')) || 0
  const animations = parseInt(localStorage.getItem('animation.' + event)) || 0

  if (animationCount < MAX_ANIMATION_COUNT_PER_WEEK) {
    localStorage.setItem('animation.' + event, animations + 1)
    // Check if the event should trigger an animation
    if (showAnimationForEvent[event] && !showAnimationForEvent[event](animations)) {
      return
    }
    localStorage.setItem('animation.count', animationCount + 1)
    bus.$emit(eventNames.SHOW_ANIMATION)
  }
})
