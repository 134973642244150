<template>
  <div>
    <v-card
      v-if="contractDetails?.endDate"
      outlined
      class="mb-4 header mt-md-4"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
          >
            <v-icon
              class="mr-2"
              color="black"
            >
              mdi-account-cancel
            </v-icon>
            <span style="word-break: break-word;">
              {{ $t('alerts.canceled-subscription.title', { platform: readablePlatformName, date: formatDate(contractDetails?.endDate,'DATE_LONG')}) }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <p>
              <span v-if="!isPlatformMarkero">
                {{ $t('alerts.canceled-subscription.question') }}<br>
                {{ $t('alerts.canceled-subscription.info', { platform: readablePlatformName }) }}<br>
              </span>
              <a href="/settings/contract">{{ $t('alerts.canceled-subscription.link') }}</a>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import billwerkPortalMixin from '@/mixins/billwerkPortal'
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [billwerkPortalMixin, brandingMixin]
}
</script>
