<template>
  <v-alert
    v-if="dunningLevel > 0"
    type="error"
    prominent
  >
    <span class="text-h4">{{ $t('alerts.dunning.title') }}</span>

    <p class="text-body-1 mt-2">
      {{ $t('alerts.dunning.body-texts.0') }}
      <br>
      {{ $t('alerts.dunning.body-texts.1') }}
      <br>
      {{ $t('alerts.dunning.body-texts.2') }}
      <router-link to="/settings/payment">
        <span class="font-weight-bold">{{ $t('alerts.dunning.body-texts.3') }}</span>
      </router-link>
      {{ $t('alerts.dunning.body-texts.4') }}
    </p>
  </v-alert>
</template>

<script>
import GET_DUNNING_LEVEL_FOR_COMPANY from './GetDunningLevelForCompany.gql'

export default {
  data () {
    return {
      dunningLevel: 0
    }
  },
  apollo: {
    dunningLevel: {
      query: GET_DUNNING_LEVEL_FOR_COMPANY,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
