const COOKIE_NAME = 'utm_params'
const MAX_AGE = 2592000 // 30 days
const MAX_PARAM_LENGTH = 100

/**
 * We store the UTM parameters in a cookie so that we can use them later (e.g. for the registration).
 * The cookie is stored for 30 days.
 *
 * NOTE: The same cookie is being set on the website as well. So if it's changed here, most likely it should be changed there as well.
 *
 * VUE_APP_UTM_COOKIE_DOMAIN must be set in order to work. Remember that it must work on the website (root domain) and the app (subdomain) as well.
 *  For markero it would be ".markero.com".
 */
export default {
  install (Vue) {
    Vue.prototype.$utm = {
      storeUtmParams,
      getUtmParams
    }
  }
}

function storeUtmParams () {
  const params = new URLSearchParams(window.location.search)
  const utmParams = {}

  ;['source', 'medium', 'campaign', 'term', 'content'].forEach(param => {
    const urlParam = `utm_${param}`
    if (params.has(urlParam)) {
      const dangerousChars = /[<>;"']/g
      let value = params.get(urlParam)
      value = value.replace(dangerousChars, '')
      utmParams[param] = value.substring(0, MAX_PARAM_LENGTH)
    }
  })

  if (Object.keys(utmParams).length > 0) {
    const cookieValue = encodeURIComponent(JSON.stringify(utmParams))
    const secure = process.env.NODE_ENV === 'production' ? 'Secure;' : ''
    document.cookie = `${COOKIE_NAME}=${cookieValue}; domain=${process.env.VUE_APP_UTM_COOKIE_DOMAIN}; path=/; SameSite=Lax; ${secure} max-age=${MAX_AGE};`
  }
}

function getUtmParams () {
  const cookies = document.cookie.split('; ')

  const utmCookie = cookies.find(cookie => cookie.startsWith(`${COOKIE_NAME}=`))
  if (!utmCookie) {
    return null
  }

  try {
    const utmParams = decodeURIComponent(utmCookie.split('=')[1])
    return JSON.parse(utmParams)
  } catch (error) {
    return null
  }
}
