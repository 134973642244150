import { getInstance } from 'feature-config'
import { getInstance as getAuthInstance } from '../auth/index'
import { authGuard } from '../guards/auth'
import featureNames from '@/lib/featureNames'
import COMPANY_STATE from '@/guards/CompanyState.gql'

/**
 * Checks if sign up feature is active.
 * If so check is user is in SELF_ONBOARDING and show registration wizard.
 * If not, route to dashboard.
 *
 * @param {string} apolloProvider apollo instance
 * @returns {void}
 * @throws {Error}
 */
export function getSignUpGuard (apolloProvider) {
  return async (to, from, next) => {
    authGuard(async () => {
      const auth = getAuthInstance()
      if (!auth.isAuthenticated) return next()
      // Legal pages that have to be visible
      if (['privacy', 'logout'].includes(to.name)) return next()

      const featureConfig = getInstance()
      await featureConfig.loadAllFeaturesForCompany(auth.user.companyId, { apolloProvider, reload: false })
      const signUpFeature = featureConfig.featuresBySlug[featureNames.SIGNUP]

      if (signUpFeature?.isActive) {
        const apolloClient = apolloProvider.defaultClient

        const { data: { company } } = await apolloClient.query({
          query: COMPANY_STATE,
          variables: {
            id: auth.user.companyId
          },
          fetchPolicy: 'no-cache'
        })

        if (!company) {
          throw new Error('Company not found:')
        }
        if (company?.state?.stringValues.includes('SELF_ONBOARDING') && to.name === 'registration') return next()
        if (company?.state?.stringValues.includes('SELF_ONBOARDING')) return next({ name: 'registration', replace: true })
        else if (to.name === 'registration') {
          return next({ name: 'dashboard', replace: true })
        }
      } else {
        if (to.name === 'registration') {
          return next({ name: 'dashboard', replace: true })
        }
      }
      next()
    })
  }
}
