import { PROVIDERS } from '@/lib/crm'
import featureNames from '@/lib/featureNames'

export default {
  inject: ['crmConfigData'],
  data () {
    return {
      crmConfig: null
    }
  },
  mounted () {
    this.crmConfig = this.crmConfigData.value
  },
  watch: {
    crmConfigData: {
      deep: true,
      handler (newConfigData) {
        this.crmConfig = newConfigData.value
      }
    }
  },
  computed: {
    providers () {
      const providers = this.$features.feature(featureNames.CRM)?.config?.providers || []
      return PROVIDERS.filter(provider => providers?.includes(provider.value))
    },
    readableCrmName () {
      return PROVIDERS.find(({ value }) => value === this.crmConfig?.crmProvider)?.text || 'Ihrem CRM'
    },
    hasCrmLink () {
      return this.crmConfig?.crmProvider !== 'ONOFFICE'
    },
    isCrmActive () {
      return this.crmConfig?.isActive
    }
  }
}
