<template>
  <v-card
    flat
    class="d-flex flex-column mission-success"
  >
    <v-row>
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        sm="3"
      >
        <div class="design-wrapper">
          <Leaf
            class="design-elements"
            width="1000"
            style="top:-1200px; right: 100px; opacity: 0.2"
            fill
          />

          <Leaf
            class="design-elements"
            width="60"
            style="top:10px; right: 150px; opacity: 0.8"
            stroke
            :stroke-width="1"
          />

          <Leaf
            class="design-elements"
            width="60"
            style="top:10px; right:130px; opacity: 0.3"
            fill
          />

          <SingleCircle
            class="design-elements"
            width="30px"
            style="top: 130px; right: 210px; opacity: 0.8"
            stroke
          />

          <SingleCircle
            class="design-elements"
            width="30px"
            style="top: 130px; right: 200px; opacity: 0.3"
            fill
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card-title class="d-flex">
          <h5>
            {{ missionHeaderTitle }}
          </h5>
        </v-card-title>

        <v-card-text class="px-4 content text-body-2">
          <p>
            {{ $t('mission-control.success.body-texts.0') }}
          </p>
          <p>
            {{ $t('mission-control.success.body-texts.1') }}
          </p>
        </v-card-text>

        <v-row class="mt-2 mr-4 mt-md-6 footer">
          <v-btn
            class="primary"
            href="https://calendly.com/bottimmo-marketing-experten/marketing-beratung?utm_source=upgrade-cta&utm_medium=app&utm_campaign=sales"
            target="_blank"
            rel="noopener noreferrer"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('buttons.mission-control.calendly') }}
            <v-icon
              class="ml-2"
              size="14"
            >
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import SingleCircle from '@/components/design-elements/singleCircle.vue'

export default {
  components: {
    Leaf,
    SingleCircle
  },
  computed: {
    missionHeaderTitle () {
      return this.$auth.user.given_name ? `${this.$t('mission-control.success.title')},  ${this.$auth.user.given_name}!` : `${this.$t('mission-control.success.title')}!`
    }
  }
}
</script>

<style scoped>
.mission-success {
  overflow: hidden;
  height: 365px;
}
@media (max-width: 745px) {
  .mission-success {
    overflow-y: scroll;
  }
}
.content {
  flex: 1 1 auto;
}

.footer {
  flex: 0 1 80px;
  justify-content: flex-end;
}

.design-wrapper {
  position: relative;
  width:300px;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.v-btn:not(.v-btn--round).v-size--small {
    height: 28px;
    padding: 0 2px;
    margin-bottom: 10px;
}
</style>
