<template>
  <div>
    <iframe
      width="100%"
      src="https://www.youtube.com/embed/N6w_cJpLBps"
      title="YouTube video player"
      frameborder="0"
      allowfullscreen
      style="aspect-ratio: 854/480;border-radius: 5px"
    />
  </div>
</template>
<script>
export default {}
</script>
