<template>
  <component :is="component" />
</template>
<script>
import brandingMixin from '@/mixins/branding'
import ResetPasswordBottimmo from './bottimmo/view'
import ResetPasswordMarkero from './markero/view'
export default {
  mixins: [brandingMixin],
  computed: {
    component () {
      return this.isPlatformMarkero ? ResetPasswordMarkero : ResetPasswordBottimmo
    }
  }
}
</script>
