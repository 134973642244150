import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import { appBranding } from '@/lib/features'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    themes: {
      light: {
        primary: (appBranding.isActive && appBranding.config.primary),
        secondary: (appBranding.isActive && appBranding.config.secondary),
        success: (appBranding.isActive && appBranding.config.success) || '#4CAF50',
        red: '#FA0143',
        orange: '#FE9930',
        error: '#FA0143',
        info: '#4895EF',
        warning: '#FE9930',
        leadActivity: '#07BEB8',
        systemActivity: '#4895EF',
        userActivity: '#C77DFF',
        skyLight: '#A2D2FF',
        dogerBlue: '#4895EF',
        markero: {
          black: '#000000',
          lightYellow: '#ffd518',
          midYellow: '#f9b004',
          orange: '#f18815',
          lightOrange: '#FF8A00',
          blue: '#377df6',
          darkBlue: '#1668F5',
          lightBlue: '#37C6F6',
          darkGrey: '#414142',
          blueGradient: 'linear-gradient(to right, #37C6F6, #377DF6)'
        }
      }
    }
  }
})
