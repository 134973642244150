<template>
  <v-btn
    elevation="3"
    :disabled="disabled || loading"
    v-bind="$attrs"
  >
    <v-progress-circular
      v-if="loading"
      class="mr-2"
      :color="isPlatformMarkero ? 'white' : 'primary'"
      width="3"
      size="20"
      indeterminate
    />
    <slot />
  </v-btn>
</template>

<script>
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  props: {
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  }
}
</script>
