<template>
  <v-alert
    v-if="company?.deleteOn"
    type="error"
    prominent
  >
    <div class="ml-4">
      <span class="text-h4">{{ $t('alerts.deletion.title') }}</span>

      <p class="text-body-1 mt-2">
        <b>{{ $t('alerts.deletion.body-texts.0', { date: formatDate(company?.deleteOn) }) }}</b>
        <br>
        {{ $t('alerts.deletion.body-texts.1') }}
        <router-link to="/settings/contract">
          <span class="font-weight-bold text--black">{{ $t('alerts.deletion.body-texts.2') }}</span>
        </router-link>
        {{ $t('alerts.deletion.body-texts.3') }}
      </p>
    </div>
  </v-alert>
</template>

<script>
import COMPANY_DELETION from './CompanyDeletion.gql'

export default {
  apollo: {
    company: {
      query: COMPANY_DELETION,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
