<template>
  <div>
    <v-card
      v-if="hasTodos"
      outlined
      class="d-flex flex-column mt-4"
    >
      <v-card-title>
        {{ $t('mission-control.title') }}
      </v-card-title>

      <v-card-text>
        <v-row class="d-flex">
          <v-col
            cols="12"
            lg="8"
            md="8"
            sm="8"
          >
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab>
                {{ $t('mission-control.tabs.next') }} ({{ otherTodos.length }})
              </v-tab>
              <v-tab
                :disabled="completedTodos.length === 0"
              >
                {{ $t('mission-control.tabs.finished') }} ({{ completedTodos.length }})
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tab"
              class="pt-4"
            >
              <v-tab-item>
                <v-card
                  outlined
                  :class="{'todo-height': $vuetify.breakpoint.mdAndUp}"
                >
                  <v-card-text
                    class="todo-overflow"
                  >
                    <v-row
                      v-for="(singleTodo,i) in otherTodos"
                      :key="singleTodo.slug"
                    >
                      <v-col>
                        <TodoItem
                          :todo="singleTodo"
                          :is-highlighted="i === 0"
                          @startTodo="startTodo"
                          @completeTodo="completeTodo"
                        />
                        <v-divider
                          v-if="i < otherTodos.length-1"
                          class="mt-6"
                        />
                      </v-col>
                    </v-row>
                    <MissionSuccessCard
                      v-if="otherTodos.length === 0"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card
                  outlined
                  :class="{'todo-height': $vuetify.breakpoint.xsAndUp}"
                >
                  <v-card-text class="todo-overflow">
                    <v-row
                      v-for="(singleTodo,i) in completedTodos"
                      :key="singleTodo.slug"
                    >
                      <v-col>
                        <TodoItem
                          :todo="singleTodo"
                          @startTodo="startTodo"
                          @completeTodo="completeTodo"
                        />
                        <v-divider
                          v-if="i < completedTodos.length-1"
                          class="mt-6"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col
            class="mt-sm-16"
            cols="12"
            sm="4"
          >
            <MissionProgress
              :completed-todos-percentage="completedTodosPercentage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import TodoItem from './TodoItem.vue'
import MissionProgress from './MissionProgress.vue'
import MissionSuccessCard from './MissionSuccessCard.vue'
import GET_TODOS from './queries/GetTodos.gql'
import START_TODO from '@/modules/todo/queries/StartTodo.gql'
import COMPLETE_TODO from '@/modules/todo/queries/CompleteTodo.gql'
import todoStates from './enums/ToDoStates'
import { sortByDate } from '@/lib/sort-functions'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    TodoItem,
    MissionProgress,
    MissionSuccessCard
  },
  mixins: [featureMixin],
  data () {
    return {
      todos: [],
      tab: null
    }
  },
  apollo: {
    todos: {
      query: GET_TODOS,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId
          }
        }
      }
    }
  },
  computed: {
    hasTodos () {
      return this.todos && this.todos.length > 0
    },
    allowedTodos () {
      const enabledTodosByFeature = {
        'import-contact': this.isFeatureActive(this.featureNames.LEAD_IMPORT),
        'invite-contact': this.isFeatureActive(this.featureNames.ACQUISITION_BOOSTER),
        'property-request': true,
        'use-return-call': true,
        'use-valuation': this.isFeatureActive(this.featureNames.VALUATION),
        calendly: true,
        'facebook-site': true,
        'facebook-post': true,
        'calendly-expert': true,
        partner: this.isFeatureActive(this.featureNames.PARTNER),
        'contact-data': true,
        'product-tour': true,
        'new-lead': true
      }
      return this.todos.filter(todo => enabledTodosByFeature[todo.slug])
    },
    sortedTodos () {
      return ([...this.allowedTodos]).sort((a, b) => b.priority - a.priority)
    },
    otherTodos () {
      const priorityTodos = ([...this.sortedTodos])
        .filter(todo => todo.startConditionEvent && todo.state !== todoStates.FINISHED)
        .sort(sortByDate('updatedAt'))

      const startedTodos = ([...this.sortedTodos])
        .filter(todo => todo.state === todoStates.STARTED)

      const others = ([...this.sortedTodos])
        .filter(todo => todo.state !== todoStates.STARTED && todo.state !== todoStates.FINISHED)

      return [...new Set([...priorityTodos, ...startedTodos, ...others])]
    },
    completedTodos () {
      return ([...this.sortedTodos]).filter(todo => todo.state === todoStates.FINISHED).sort(sortByDate('updatedAt', 'desc'))
    },
    completedTodosPercentage () {
      if (!this.todos) return 0
      const completedTodos = this.allowedTodos.filter(todo => todo.state === todoStates.FINISHED)
      const percentage = Math.round((completedTodos.length / this.allowedTodos.length) * 100)
      return percentage
    }
  },
  mounted () {
    // listen for appcues events to complete a todo
    // @see https://docs.appcues.com/en_US/dev-api-data/javascript-api-developer
    if (window.Appcues) {
      window.Appcues.on('flow_completed', async (event) => {
        const todo = this.allowedTodos.find(todo => todo.externalFlowId === event.flowId)
        if (event.flowId === todo.externalFlowId) {
          await this.completeTodo(todo)
        }
      })
    }

    // listen for our internal end condition events to complete a todo
    bus.$on(this.endConditionEvent, async ({ slug }) => {
      const todo = this.allowedTodos.find(todo => todo.slug === slug)
      if (slug === todo.slug) {
        await this.completeTodo(todo)
      }
    })
  },
  methods: {
    async startTodo ({ slug }) {
      try {
        await this.$apollo.mutate({
          mutation: START_TODO,
          variables: {
            input: {
              todoTemplateSlug: slug
            }
          },
          refetchQueries: [
            { query: GET_TODOS, variables: { input: { companyId: this.$auth.user.companyId } } }
          ]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.mission-control.start.success') })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.mission-control.start.error') })
      }
    },
    async completeTodo ({ slug, endConditionEvent }) {
      try {
        await this.$apollo.mutate({
          mutation: COMPLETE_TODO,
          variables: {
            input: {
              todoTemplateSlug: slug,
              ...endConditionEvent && {
                event: endConditionEvent
              }
            }
          },
          refetchQueries: [
            { query: GET_TODOS, variables: { input: { companyId: this.$auth.user.companyId } } }
          ]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.mission-control.stop.success') })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.mission-control.stop.error') })
      }
    }
  }
}
</script>

<style scoped>
.todo-height {
  height: 400px;
}
.todo-overflow {
  max-height: 400px;
  overflow: scroll;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
